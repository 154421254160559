import {
    SET_SWEET_ALERT,
    REMOVE_SWEET_ALERT,
    SET_CONFIRM_SWEET_ALERT,
    REMOVE_CONFIRM_SWEET_ALERT,
    SET_LOADER,
    SET_SHOW_ALERT
} from './types'

export const setSweetAlert = (payload) => dispatch => {
    document.body.style.overflow = 'hidden'
    dispatch({ type: SET_SWEET_ALERT, payload })
    document.body.style.overflow = 'hidden'
}

export const removeSweetAlert = () => dispatch => {
    dispatch({ type: REMOVE_SWEET_ALERT })
    document.body.style.overflow = window.location.pathname.match('/private-chat') ? 'hidden' : 'visible'
}

export const setConfirmSweetAlert = (payload) => dispatch => {
    dispatch({ type: SET_CONFIRM_SWEET_ALERT, payload })
    document.body.style.overflow = 'hidden'
}
export const removeConfirmSweetAlert = () => dispatch => {
    dispatch({ type: REMOVE_CONFIRM_SWEET_ALERT })
    document.body.style.overflow = window.location.pathname.match('/private-chat') ? 'hidden' : 'visible'
}

export const setAlertLoader = (payload) => dispatch => {
    dispatch({ type: SET_LOADER, payload })
}

export const setShowAlertForUnlockContent = (payload) => dispatch => {
    dispatch({ type: SET_SHOW_ALERT, payload })
}
