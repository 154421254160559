import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PageWrapper from '../Layouts/PageWrapper'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import ChatUserList from './ChatUserList'
import socket from '../../utils/socket'
import {
    getUserList,
    setSelectedChatModelId,
    setIsChatScreenOpen,
    setSelectedUserId,
    setSelectedChatId,
    updateUsersLastMessage
} from './../../actions/chatActions'
import styled from 'styled-components'
import Messages from './Messages'
import _ from 'lodash'
import classNames from 'classnames'
import Loader from '../Layouts/Loader'

const ChatContainerDiv = styled.div`
    background:  ${props => props.backgroundColor};

    ${props => !props.isSingleModel && `
        max-width: 100% !important;
    `}

    ${props => props.isSingleModel && `
        border: 1px solid ${props.borderColor};
        border-top: none;
    `}

    @media (max-width: 991px) {
        max-width: ${props => props.isSingleModel ? '100% !important' : 'inherit'};
    }
`

const ChatRow = styled.div`
    width: 100%;
    ${props => !props.isSingleModel && `
        display: grid;
        grid-template-columns: 450px 1fr;
    `}

    @media (max-width: 991px) {
        grid-template-columns: 100%;

        #message-list {
            width: 100%;
        }
    }

    @media (max-width: 576px) {
        grid-template-columns: 100%;
        position: ${props => props.isPopupOpen ? 'static' : 'fixed'};
        left: 0;
        right: 0;
    }
`

const ChatListDiv = styled.div`
    border-right: 1px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    padding: 0 !important;
    margin: 0 !important;
    height: ${props => props.isGroupLiveStreamStarted
        ? 'calc(100dvh - 88px - env(safe-area-inset-bottom, 0))'
        : 'calc(100dvh - 44px - env(safe-area-inset-bottom, 0))'};
    overflow: scroll;
    overflow-x: hidden;

    @media (max-width: 991px) {
        height: calc(100dvh - 100px - env(safe-area-inset-bottom, 0));
        border: none;
    }

    @media (max-width: 768px) {
        height: calc(100dvh - 101px - env(safe-area-inset-bottom, 0));
    }

    ::-webkit-scrollbar {
        display: none;
    }
`

const MessageDiv = styled.div`
    padding: 0 !important;
    margin: 0 !important;
    overflow: scroll;
    overflow-x: hidden;
    height: ${props => props.isGroupLiveStreamStarted
        ? 'calc(100dvh - 88px - env(safe-area-inset-bottom, 0))'
        : 'calc(100dvh - 44px - env(safe-area-inset-bottom, 0))'};
    background: ${props => props.chatBackgroundColor};

    ::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 991px) {
        height: calc(100dvh - env(safe-area-inset-bottom, 0));
        max-height: 100dvh !important;
        position: absolute !important;
    }
`

const LoaderDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
`

const getColorScheme = (settings) => {
    const {
        chat_header_background_color,
        chat_header_font_color,
        chat_input_section_background_color,
        chat_sender_message_background_color,
        chat_sender_message_font_color,
        chat_receiver_message_background_color,
        chat_receiver_message_font_color,
        chat_background_color,
        chat_user_list_background_color,
        chat_user_list_font_color,
        chat_selected_user_background_color,
        chat_selected_user_font_color,
        chat_filter_button_background_color,
        chat_filter_button_font_color,
        chat_button_background_color,
        chat_button_font_color,
        chat_border_color,
        card_background_color,
        content_color,
        content_font_color,
        site_background_color,
        site_font_color,
        card_header_border_color,
        chat_unlock_button_background_color,
        chat_unlock_button_font_color
    } = settings
    return {
        chatHeaderBackgroundColor: chat_header_background_color || card_background_color,
        chatHeaderFontColor: chat_header_font_color || site_font_color,
        chatInputSectionBackgroundColor: chat_input_section_background_color || card_background_color,
        chatSenderMessageBackgroundColor: chat_sender_message_background_color || content_color,
        chatSenderMessageFontColor: chat_sender_message_font_color || content_font_color,
        chatSenderButtonBackgroundColor: chat_sender_message_font_color || content_font_color,
        chatSenderButtonFontColor: chat_sender_message_background_color || content_color,
        chatReceiverMessageBackgroundColor: chat_receiver_message_background_color || content_font_color,
        chatReceiverMessageFontColor: chat_receiver_message_font_color || content_color,
        chatReceiverButtonBackgroundColor: chat_receiver_message_font_color || content_color,
        chatReceiverButtonFontColor: chat_receiver_message_background_color || content_font_color,
        chatBackgroundColor: chat_background_color || site_background_color,
        chatFilterBackgroundColor: chat_user_list_background_color || card_background_color,
        chatUserListBackgroundColor: chat_user_list_background_color || card_background_color,
        chatUserListFontColor: chat_user_list_font_color || site_font_color,
        chatSelectedUserBackgroundColor: chat_selected_user_background_color || content_color,
        chatSelectedUserFontColor: chat_selected_user_font_color || content_font_color,
        chatFilterButtonBackgroundColor: chat_filter_button_background_color || content_color,
        chatFilterButtonFontColor: chat_filter_button_font_color || content_font_color,
        chatButtonBackgroundColor: chat_button_background_color || content_color,
        chatButtonFontColor: chat_button_font_color || content_font_color,
        chatUnlockButtonBackgroundColor: chat_unlock_button_background_color || content_color,
        chatUnlockButtonFontColor: chat_unlock_button_font_color || content_font_color,
        chatBorderColor: chat_border_color || card_header_border_color,
        messageEllipsisColor: chat_sender_message_font_color || content_color
    }
}

function Chat(props) {
    const { chat, auth } = props
    const colorScheme = getColorScheme(auth.appSettings)
    const { chatUserListBackgroundColor, chatBackgroundColor, chatBorderColor, chatUserListFontColor } = colorScheme
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {
        selectedUserId,
        isChatScreenOpen,
        userList,
        searchedUsername
    } = chat

    const [page, setPage] = useState(1)
    const [loadMoreUser, setLoadMoreUser] = useState(false)

    const updateChatInputHeight = () => {
        const nav = document.querySelector('.navbar')
        const chatList = document.getElementById('message-list')
        if (chatList && nav) {
            const navHeight = nav.offsetHeight
            chatList.style.maxHeight = `calc(100vh - ${navHeight}px)`
        }
    }

    useEffect(() => {
        if (userList && userList.length === 0) {
            let data = {
                pageNum: props.chat.currentPage === 0 ? 1 : props.chat.currentPage,
                sortBy: props.chat.sortBy === '' ? 'last_message_time' : props.chat.sortBy,
                userName: searchedUsername,
                sortBySubscribers: props.chat.sortBySubscribers === '' ? 'all' : props.chat.sortBySubscribers,
                isFilter: true
            }
            if (props.auth.user.isAdmin === false) {
                data.email = props.auth.user.email
                data.universal_login = props.auth.user.universal_login
            }
            props.getUserList(data)
        }

        window.addEventListener('resize', updateChatInputHeight)
        return () => {
            window.removeEventListener('resize', updateChatInputHeight)
        }

    }, [])

    useEffect(() => {
        // Open chat screen directly for user, if there is only one model to chat with
        if (!props.auth.isAdmin && props.chat.userList && props.chat.userList.length === 1) {
            props.setSelectedChatId(props.chat.userList[0]._id, props.history)
            props.setIsChatScreenOpen(true)
        }
        if (!props.auth.isAdmin && userList?.length > 1) {
            userList.forEach(user => {
                if (user.domain !== window.location.hostname) {
                    const data = {
                        userId: props.auth.user._id,
                        channel: user.domain,
                        isUniversal: true,
                        email: props.auth.user.email
                    }
                    socket.emit('USER_ONLINE', data)
                    socket.on('SEND_MESSAGE_RES', (data) => {
                        if (data.type === 'text') {
                            props.updateUsersLastMessage({ user_id: props.chat.selectedUserId, message: data.message, type: data.type, isAdmin: false })
                        }
                        const objDiv = document.getElementById('message-list')
                        if (objDiv) {
                            objDiv.scrollTop = objDiv.scrollHeight
                        }
                        // TODO: Handle data/errors if needed
                    })
                }
            })
            return () => {
                socket.off('SEND_MESSAGE_RES')
            }
        }
    }, [props.chat.userList])

    const handleScroll = (e) => {
        e.preventDefault()
        if (props.chat.totalPages > page) {
            let winScroll = 0
            let height = 0

            // Use the event target to get scrollTop and height
            winScroll = e.target.scrollTop
            height = e.target.scrollHeight - e.target.clientHeight
            const scrolled = winScroll / height
            if (scrolled > 0.98) {
                setPage(props.chat.currentPage + 1)
            }
        }
    }

    useEffect(() => {
        document.body.style.overflowY = 'hidden'
        const el = document.getElementById('chat-list')
        if (el) {
            el.addEventListener('scroll', handleScroll)
        }

        return () => {
            document.body.style.overflowY = 'auto'
            if (el) {
                el.removeEventListener('scroll', handleScroll)
            }
        }
    })

    useEffect(() => {
        if (page > 1) {
            setLoadMoreUser(true)
            const fetchData = async () => {
                let data = {
                    pageNum: page,
                    sortBy: props.chat.sortBy === '' ? 'last_message_time' : props.chat.sortBy,
                    userName: searchedUsername,
                    sortBySubscribers: props.chat.sortBySubscribers === '' ? 'all' : props.chat.sortBySubscribers,
                    isInfiniteScroll: true
                }
                await props.getUserList(data)
                setLoadMoreUser(false)
            }
            fetchData()
        }
    }, [page])

    useEffect(() => {
        updateChatInputHeight()
        if ((auth.user.isAdmin === true) && (props.match.params.id !== undefined) && (selectedUserId !== props.match.params.id)) {
            props.setSelectedUserId(props.match.params.id, props.history)
            props.setIsChatScreenOpen(true)
        }

        if (props.match.params.id === undefined && auth.user.isAdmin) {
            props.setSelectedUserId('', props.history)
            props.setIsChatScreenOpen(false)
        }
    }, [selectedUserId, props.match.params.id])

    const showMessage = auth.user.isAdmin ? _.isEmpty(props.match.params.id) !== true : selectedUserId

    let showUserList = true

    if (auth.user.isAdmin === true) {
        showUserList = true
    } else if (auth.user.isAdmin === false && userList.length <= 1) {
        showUserList = false
    }

    useEffect(() => {
        if (props.auth.isAdmin) {
            socket.emit('MODEL_ONLINE', {})
        }
    }, [])

    const isModelListLoadingForUser = !auth.user.isAdmin && userList && userList.length === 0

    return (
        <div>
            <PageWrapper showFooter={false}>
                <WebsiteFontColor>
                    <ChatContainerDiv
                        backgroundColor={chatBackgroundColor}
                        className={classNames('container p-0', {
                            'm-0': !auth.user.isAdmin && props.chat.userList && props.chat.userList.length !== 1
                        })}
                        isSingleModel={!auth.user.isAdmin && props.chat.userList && props.chat.userList.length === 1 ? true : false}
                        borderColor={chatBorderColor}
                    >
                        {isModelListLoadingForUser
                            ? <LoaderDiv>
                                {/* full screen loader for user only  */}
                                <Loader
                                    color={chatUserListFontColor}
                                    loading={true}
                                    size={10} />
                            </LoaderDiv>
                            :
                            <ChatRow isPopupOpen={isPopupOpen} isSingleModel={!auth.user.isAdmin && props.chat.userList && props.chat.userList.length === 1 ? true : false}>
                                {
                                    showUserList &&
                                    <ChatListDiv
                                        className={
                                            classNames('p-0 chat-list', {
                                                'd-none d-lg-block': isChatScreenOpen
                                            })
                                        }
                                        id='chat-list'
                                        borderColor={chatBorderColor}
                                        backgroundColor={chatUserListBackgroundColor}
                                        isGroupLiveStreamStarted={props.liveStream.isGroupLiveStreamStarted}>
                                        <ChatUserList
                                            setIsPopupOpen={setIsPopupOpen}
                                            colorScheme={colorScheme}
                                            setPage={setPage}
                                            loadMoreUser={loadMoreUser} />
                                    </ChatListDiv>
                                }
                                {showMessage &&
                                    <MessageDiv
                                        id='message-list'
                                        chatBackgroundColor={chatBackgroundColor}
                                        isGroupLiveStreamStarted={props.liveStream.isGroupLiveStreamStarted}
                                        className={classNames({
                                            'd-none d-lg-block': !isChatScreenOpen
                                        })}>
                                        <Messages colorScheme={colorScheme} />
                                    </MessageDiv>
                                }
                            </ChatRow>
                        }
                    </ChatContainerDiv>
                </WebsiteFontColor>
            </PageWrapper>
        </div>
    )
}

Chat.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getUserList: PropTypes.func.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    setIsChatScreenOpen: PropTypes.func.isRequired,
    setSelectedChatId: PropTypes.func.isRequired,
    updateUsersLastMessage: PropTypes.func.isRequired,
    liveStream: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media,
    liveStream: state.liveStream
})

export default connect(
    mapStateToProps,
    {
        getUserList,
        setSelectedUserId,
        setSelectedChatModelId,
        setIsChatScreenOpen,
        setSelectedChatId,
        updateUsersLastMessage
    }
)(withRouter(Chat))
