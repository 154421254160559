import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import ChatInput from './ChatInput'
import { sendTextMessage, setSelectedChatModelId, toggleChatTipPopup, updateUserList, addNewMessageInEnd, clearChatTextInput, incMessageId } from '../../actions/chatActions'
import { addPayPerMessageCredit, removePayPerMessageCredit } from '../../actions/authActions'
import ChatInputHandler from './ChatInputHandler'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import AddFundForm from '../crypto/AddFundForm'
import { purchaseFromWallet, updateWalletAmount } from '../../actions/cryptoPaymentActions'
import _ from 'lodash'
import { googleAnalyticsTrackEvent } from '../../utils/GoogleAnalyticsEvent'
import { stickyIoTipFromChat } from '../../actions/stickyIoActions'
import { TipPayment } from '../../actions/hybridPaymentAction'
import socket from '../../utils/socket'

const MainDiv = styled.div`
    background: ${props => props.backgroundColor};
    border-top: 1px solid ${props => props.borderColor};
    z-index:  ${props => props.isShowInput ? '1' : '2'} !important;
    position: sticky !important;
    padding: env(safe-area-inset-top, 0.25rem) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0.25rem) env(safe-area-inset-left, 0px) !important;

    @media(max-width: 576px){
        position: fixed !important;
        bottom: 0 !important;
        width: 100%;
    }
`

const InputButton = styled.button`
    padding: 0 !important;
    background-color: ${props => props.buttonColor};
    color: ${props => props.buttonFontColor};
    height: 34px;
    min-width: 34px !important;
    width: 34px !important;
    border-radius: 50% !important;
    box-shadow: none;

    &:hover {
        color: ${props => props.buttonFontColor};
        box-shadow: none;
    }
`

const ChatInputSection = (props) => {

    const { auth, chat, colorScheme } = props
    const { user, appSettings } = auth
    const { is_pay_per_message_enabled } = appSettings
    const { showBootstrapNavbar, messageId } = chat
    const {
        chatInputSectionBackgroundColor,
        chatButtonBackgroundColor,
        chatButtonFontColor,
        chatBorderColor
    } = colorScheme

    const {
        isAdmin,
        role
    } = user
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [inputType, setInputType] = useState('')
    const [showAddFundPopup, setShowAddFundPopup] = useState(false)
    const [remainAmount, setRemainAmount] = useState(0)
    const [cryptoPaymentType, setCryptoPaymentType] = useState('')
    const [tipAmount, setTipAmount] = useState(0)
    const textareaRef = useRef(null)

    const resetForm = () => {
        setMessage('')
        setLoading(false)
    }

    const tipUsingCrypto = async (amount, walletBalance, tipMessage, callback) => {
        const tipAmount = Number(parseFloat(amount)).toFixed(2)
        if (walletBalance >= tipAmount) {
            const data = {
                payment_for: 'tips',
                amount: tipAmount.toString(),
                tipMessage: tipMessage
            }
            if (props.auth.user.universal_login === true) {
                const userId = props.chat.selectedUserId
                const userDomain = props.chat.userList.filter(obj => obj._id === userId)
                data.domain = userDomain[0].domain
                data.email = props.auth.user.email
            }
            const res = await props.purchaseFromWallet(data)
            if (res.success === 1) {
                props.updateWalletAmount(res.data.wallet_balance)
                setShowAddFundPopup(false)
                setRemainAmount(0)
                if (callback) {
                    return callback(res)
                }
                props.toggleChatTipPopup(false)
                props.setSweetAlert({ description: res.data.message })

            } else {
                const message = _.get(res, 'message', 'something went wrong')
                props.setSweetAlert({ description: message })
                props.toggleChatTipPopup(false)
            }
        } else {
            const remainAmount = Math.ceil(tipAmount - walletBalance)
            if (walletBalance === 0.00) {
                setShowAddFundPopup(true)
                setRemainAmount(0)
            } else {
                setShowAddFundPopup(true)
                setRemainAmount(remainAmount)
            }
        }
    }

    const payPerMessageWalletBalanceUpdated = (amount) => {
        props.updateWalletAmount(amount)
        props.setSweetAlert({ description: 'Amount Successfully Added.' })
    }

    const sendTip = async (amount, tipMessage, callback) => {
        let amountInt = parseInt(amount, 10)
        let tipsMinimumAmount = parseInt(props.auth.appSettings.tips_minimum_amount, 10)
        let tipsMaximumAmount = parseInt(props.auth.appSettings.tips_maximum_amount, 10)

        if (!((tipsMinimumAmount <= amountInt) && (amountInt <= tipsMaximumAmount))) {
            props.setSweetAlert({ description: `Choose a tip between ${tipsMinimumAmount} and ${tipsMaximumAmount}` })
        } else {
            if (!/^[0-9]+(\.[0-9]{1,2})$/.test(amount)) {
                amount = amount + '.00'
            }
            // Set google analytics add_to_cart event for tip
            googleAnalyticsTrackEvent('add_to_cart', '', amount, 'chat', 'tip', '')
            let confirmMessage = `Please Confirm Tip of $${amount}.`
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmMessage += `You currently have $${props.auth.user.wallet_amount} in your wallet. Amount will be debited from your wallet balance.`
            }

            let confirmation
            if (props.auth.user.default_payment_method !== 'crypto_currency') {
                confirmation = window.confirm(confirmMessage)
            } else {
                if (props.auth.user.wallet_amount >= parseFloat(amount)) {
                    confirmation = window.confirm(confirmMessage)
                } else {
                    confirmation = true
                }
            }

            if (confirmation) {
                setTipAmount(amount)
                let data = {
                    amount: amount,
                    recurring: false,
                    email: props.auth.user.email,
                    action: 'tips',
                    tipFrom: 'chat',
                    tipMessage: tipMessage
                }
                if (props.auth.user.universal_login === true) {
                    const userId = props.chat.selectedUserId
                    const userDomain = props.chat.userList.filter(obj => obj._id === userId)
                    data.domain = userDomain[0].domain
                }
                if (props.auth.user.default_payment_method === 'crypto_currency') {
                    tipUsingCrypto(amount, props.auth.user.wallet_amount, tipMessage, (res) => {
                        callback(res)
                    })
                } else {
                    const payment = await props.TipPayment(data, props.auth.user._id)
                    payment.show_alert = false
                    callback(payment)
                }
            }
        }
    }

    function scrollToBottom() {
        // get the messageList container and set the scrollTop to the height of the container
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    function payPerMessageCreditRemove() {
        if (
            auth.appSettings.is_pay_per_message_enabled === true &&
            auth.user.payPerMessageCredit > 0
        ) {
            props.removePayPerMessageCredit()
        }
    }

    function payPerMessageCreditAdd() {
        props.addPayPerMessageCredit()
    }

    const sendMessage = (data, callback) => {
        props.sendTextMessage(data, (status) => {
            if (status === false) {
                // props.history.push('profile/add-new-payment-method')
            } else {
                callback()
            }
        })
    }

    const handleTextMessage = (data) => {
        setLoading(true)
        props.incMessageId()
        const modifiedData = {
            ...data,
            senderId: data.sender,
            receiverId: data.receiver
        }
        props.addNewMessageInEnd(modifiedData, data.receiver)
        resetForm()
        const updatedUserList = props.chat.userList.map(user => {
            if (user._id === data.receiver) {
                user.last_message = data.message
                user.last_message_time = new Date().toISOString()
            }
            return user
        })
        props.updateUserList(updatedUserList)
        sendMessage(data, () => { })
    }

    const payPerMessageConfirmationAndAlert = (messageAmount) => {
        let confirmationMessage
        if (props.auth.user.payPerMessageCredit === 0) {
            const payPerMessageAmount = messageAmount === '' ? props.auth.appSettings.pay_per_message_amount : messageAmount
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                confirmationMessage = `You currently have $${props.auth.user.wallet_amount} in your wallet. Sending this message will charge $${payPerMessageAmount}. It will be debited from your wallet balance.`
            } else {
                confirmationMessage = `Sending this message will charge $${payPerMessageAmount} to the payment method on file for your account.`
            }
        } else {
            confirmationMessage = `You have ${props.auth.user.payPerMessageCredit} Message credit. This message is sent free.`
        }
        return window.confirm(confirmationMessage)
    }

    const payPerMessageCryptoPayment = (data) => {
        setShowAddFundPopup(data.showAddFundPopup)
        setRemainAmount(data.remainAmount)
        setCryptoPaymentType('chat')
        setTipAmount(data.tipAmount)
    }
    useEffect(() => {
        if (message.trim() !== '') {
            scrollToBottom()
        }
    }, [props.chat.newChatMessage])

    const handleMessage = () => {
        const messageType = 'text'
        if (message.trim() === '' && messageType === 'text') {
            const errorMessage = 'Message can\'t be empty'
            setMessage('')
            return props.setSweetAlert({ description: errorMessage })
        }
        // TODO: find message type
        if (isAdmin) {
            if (messageType === 'text') {
                if (textareaRef && textareaRef.current) {
                    textareaRef.current.focus()
                }
                const data = {
                    sender: appSettings.model_id,
                    receiver: chat.selectedUserId,
                    type: 'text',
                    fromAdmin: true,
                    message: message,
                    userId: chat.selectedUserId,
                    _id: props.auth.user.role + messageId,
                    uniqueId: props.auth.user.role + messageId
                }
                handleTextMessage(data)
                return
            }
        } else {
            if (message.length > 20000) {
                return props.setSweetAlert({ description: 'Message is too long.' })
            }
            // Check if the pay-per-message feature is enabled on the other server's website while sending a message from the current website to another website
            const currentURL = window.location.hostname
            const userId = props.chat.selectedUserId
            const userDomain = props.chat.userList.filter(obj => obj._id === userId)
            const domain = _.get(userDomain, '[0].domain', '')

            let shouldSendMessage = true
            if (props.auth.user.universal_login === true && domain !== currentURL) {
                const data = {
                    email: props.auth.user.email,
                    isUniversal: true,
                    modelDomain: domain
                }
                socket.emit('IS_ENABLE_PAY_PER_MESSAGE', data)
                const handlePayPerMessageStatus = (data) => {
                    if (data.isPayPerMessageEnabled === true && shouldSendMessage === true) {
                        const confirmation = checkPayperMessageEnable(data.isPayPerMessageEnabled, data.payPerMessageAmount)
                        const status = _.defaultTo(confirmation, false)
                        if (status === false) {
                            shouldSendMessage = false
                            return
                        }
                    }
                    if (shouldSendMessage === true) {
                        sendMessageToAdmin(messageType, data.isPayPerMessageEnabled)
                    }
                    // Remove the listener after handling the event
                    socket.off('PAY_PER_MESSAGE_STATUS', handlePayPerMessageStatus)
                }
                socket.on('PAY_PER_MESSAGE_STATUS', handlePayPerMessageStatus)

            } else {
                if (is_pay_per_message_enabled) {
                    const confirmation = checkPayperMessageEnable(is_pay_per_message_enabled)
                    if (confirmation === false) {
                        return
                    }
                }
                sendMessageToAdmin(messageType)
            }
        }
    }

    const checkPayperMessageEnable = (isEnablePayPerMessage, messageAmount = '') => {
        if (isEnablePayPerMessage === true && isAdmin === false) {
            if (props.auth.user.default_payment_method === 'crypto_currency') {
                const wallet_amount = props.auth.user.wallet_amount
                const pay_per_message_amount = messageAmount === '' ? props.auth.appSettings.pay_per_message_amount : messageAmount
                if (wallet_amount < pay_per_message_amount) {
                    const remainAmount = wallet_amount === 0.00 ? 0 : Math.ceil(pay_per_message_amount - wallet_amount)
                    const confirm = window.confirm('Your wallet balance is low. Please add funds to send message.')
                    if (confirm === true) {
                        return payPerMessageCryptoPayment({ showAddFundPopup: true, remainAmount: remainAmount, tipAmount: pay_per_message_amount })
                    }
                    return false
                }
            }
            const confirmation = payPerMessageConfirmationAndAlert(messageAmount)
            return confirmation
        }
    }

    const sendMessageToAdmin = (messageType, isEnablePayPerMessage = false) => {
        if (messageType === 'text') {
            const data = {
                type: messageType,
                fromAdmin: false,
                message: message,
                receiver: props.chat.selectedUserId,
                canChat: true,
                messageFrom: 'chat',
                userId: props.auth.user._id,
                _id: 'user' + messageId,
                uniqueId: 'user' + messageId,
                sender: props.auth.user._id,
                domain: props.chat.userList.find(user => user._id === props.chat.selectedUserId).domain,
                email: props.auth.user.email,
                isPayPerMessageEnabled: isEnablePayPerMessage
            }
            setLoading(true)
            handleTextMessage(data)
        }
    }

    useEffect(() => {
        props.setSelectedChatModelId(auth.appSettings.model_id)
    }, [])

    return (
        <>
            <MainDiv
                id='input-section'
                className='d-flex align-items-end bottom-0 left-0'
                backgroundColor={chatInputSectionBackgroundColor}
                borderColor={chatBorderColor}
                isShowInput={showBootstrapNavbar}
            >
                <div className='py-1'>
                    <InputButton
                        className='btn m-0 mx-1'
                        buttonColor={chatButtonBackgroundColor}
                        buttonFontColor={chatButtonFontColor}
                        onClick={() => setInputType('photo')}
                        disabled={loading}
                    >
                        <i className='fas fa-images'></i>
                    </InputButton>
                    {isAdmin === false &&
                        <InputButton
                            className='btn m-0 mr-1'
                            buttonColor={chatButtonBackgroundColor}
                            buttonFontColor={chatButtonFontColor}
                            onClick={() => setInputType('tip')}
                            disabled={loading}
                        >
                            <i className='fas fa-dollar-sign'></i>
                        </InputButton>
                    }
                </div>
                <div
                    className='flex-fill d-flex align-items-center py-1'>
                    <ChatInput
                        textareaRef={textareaRef}
                        auth={props.auth}
                        value={message}
                        setValue={setMessage}
                        disabled={false}
                        borderColor={chatButtonBackgroundColor}
                        chat={props.chat}
                        clearChatTextInput={props.clearChatTextInput}
                    />
                </div>
                <div className='mx-1 py-1'>
                    <InputButton
                        className='btn m-0 p-0'
                        disabled={loading}
                        onClick={handleMessage}
                        buttonColor={chatButtonBackgroundColor}
                        buttonFontColor={chatButtonFontColor}
                    >
                        <SendRoundedIcon style={{ padding: '3px', margin: '1px 5px' }} />
                    </InputButton>
                </div>
            </MainDiv>
            {role !== 'live_stream_manager' &&
                <ChatInputHandler
                    onSend={handleMessage}
                    isAdmin={isAdmin}
                    sendTip={sendTip}
                    scrollToBottom={scrollToBottom}
                    payPerMessageCreditRemove={payPerMessageCreditRemove}
                    payPerMessageCreditAdd={payPerMessageCreditAdd}
                    payPerMessageCryptoPayment={payPerMessageCryptoPayment}
                    inputType={inputType}
                    setInputType={setInputType}
                    message={message}
                    setMessage={setMessage}
                />
            }
            {
                showAddFundPopup === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: appSettings.card_background_color }}>
                            <div className='modal-header align-items-center'>
                                <button className='close' onClick={() => {
                                    setShowAddFundPopup(false)
                                    setRemainAmount(0)
                                }} style={{ color: appSettings.site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <AddFundForm
                                        onHideAddFund={() => setShowAddFundPopup(false)}
                                        type={cryptoPaymentType}
                                        transactionAmount={Number(tipAmount)}
                                        remainAmount={remainAmount}
                                        onCompleteTransaction={
                                            (updatedBalance) => {
                                                setShowAddFundPopup(false)
                                                if (updatedBalance) {
                                                    if (cryptoPaymentType === 'tips') {
                                                        tipUsingCrypto(tipAmount, updatedBalance)
                                                    }

                                                    if (cryptoPaymentType === 'chat') {
                                                        payPerMessageWalletBalanceUpdated(updatedBalance)
                                                    }
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

ChatInputSection.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    sendTextMessage: PropTypes.func.isRequired,
    removePayPerMessageCredit: PropTypes.func.isRequired,
    addPayPerMessageCredit: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    updateUserList: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateWalletAmount: PropTypes.func.isRequired,
    stickyIoTipFromChat: PropTypes.func.isRequired,
    toggleChatTipPopup: PropTypes.func.isRequired,
    TipPayment: PropTypes.func.isRequired,
    purchaseFromWallet: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    clearChatTextInput: PropTypes.func.isRequired,
    incMessageId: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media,
    liveStream: state.liveStream
})

export default connect(
    mapStateToProps,
    {
        sendTextMessage,
        removePayPerMessageCredit,
        addPayPerMessageCredit,
        setSelectedChatModelId,
        setSweetAlert,
        updateUserList,
        purchaseFromWallet,
        updateWalletAmount,
        toggleChatTipPopup,
        stickyIoTipFromChat,
        TipPayment,
        addNewMessageInEnd,
        clearChatTextInput,
        incMessageId
    }
)(withRouter(ChatInputSection))
