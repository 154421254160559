import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import crypto from 'crypto'
import PropTypes from 'prop-types'
import Loader from '../Layouts/Loader'
import socket from '../../utils/socket'
import Button from '../Layouts/Button'
import StreamWrapper from '../live-stream/StreamWrapper'
import {
    setStartStream,
    setStreamLoader,
    setLiveStreamStatus,
    setIsLive,
    setStreamJoined
} from '../../actions/streamAction'
import {
    showBootstrapNavbar,
    sendTextMessage
} from '../../actions/chatActions'
import _ from 'lodash'

const LoaderOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 1);
    z-index: 9999;
`
const JoinStreamWrapper = styled.div`
    position: fixed;
    border-top: ${props => `1px solid ${props.borderColor}`};
    border-bottom: ${props => `1px solid ${props.borderColor}`};
    top: ${props => props.navbarHeight ? props.navbarHeight : '0px'};
    left: 0;
    right:0;
    width: 100vw;
    background: white;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;

`
const OuterStreamWrapper = (props) => {
    const {
        auth,
        liveStream,
        chat
    } = props
    const {
        isLoading,
        isLive,
        streamJoined,
        streamToken,
        isModelCanGoLive,
        room,
        pubId,
        pubPvtId
    } = liveStream
    const { role, isAdmin } = props.auth.user
    const tabToken = useRef(crypto.randomBytes(6).toString('hex')).current

    const [navbarHeight, setNavbarHeight] = useState('')

    const scrollToBottom = () => {
        // get the messageLiveList div and set the scrollTop to the height of the div
        const objDiv = document.getElementById('messageLiveList')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    const joinStream = () => {
        props.setStreamJoined(true)
        if (auth.user.isAdmin === false) {
            socket.emit('JOIN_STREAM', auth.user._id)
            socket.emit('CHECK_STREAM_STARTED', auth.user._id)
        }
    }

    const stopShowStream = () => {
        props.setStreamJoined(false)
        if (auth.user.isAdmin === false) {
            socket.emit('LEFT_STREAM', auth.user._id)
        }
    }

    const goLive = () => {
        const messageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'system',
            fromAdmin: true,
            message: 'start',
            userId: chat.selectedUserId
        }
        props.sendTextMessage(messageObject, () => {
            scrollToBottom()
            props.setIsLive(true)
        })
    }

    const stopLive = () => {
        props.showBootstrapNavbar(false)
        const liveStreamMessageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'GO_LIVE_STREAM',
            fromAdmin: true,
            message: '',
            userId: chat.selectedUserId
        }
        props.sendTextMessage(liveStreamMessageObject, () => {
        })
        const messageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'system',
            fromAdmin: true,
            message: 'stop',
            userId: chat.selectedUserId
        }
        props.sendTextMessage(messageObject, () => {
            props.setIsLive(false)
            scrollToBottom()
            props.setStreamJoined(false)
        })
    }

    useEffect(() => {
        const navbar = document.querySelector('.navbar')
        const userList = props.chat.userList
        if (navbar && userList.length === 1 && window.screen.width > 992) {
            const navHeight = _.get(navbar, 'clientHeight', 0)
            setNavbarHeight(`${navHeight}px`)
        }
    }, [])

    let userJoinLiveStream = false
    if (!props.auth.user.isAdmin) {
        const userDomain = props.chat.userList.filter(obj => obj._id === props.chat.selectedUserId)
        if (window.location.hostname === userDomain[0]?.domain) {
            userJoinLiveStream = true
        }
    }

    const borderColor = auth.appSettings.chat_border_color || auth.appSettings.card_header_border_color

    return (
        <>
            {
                isLoading &&
                <LoaderOverlay>
                    <Loader
                        color='#FFFFFF'
                        loading={isLoading}
                        size={10} />
                </LoaderOverlay>
            }
            {isAdmin === false && isLive === true && streamJoined === false && navbarHeight.includes('px') && window.location.pathname.includes('/private-chat') &&
                <JoinStreamWrapper navbarHeight={navbarHeight} borderColor={borderColor}>
                    {props.auth.appSettings.model_name} is now live!
                    <Button
                        onClick={joinStream}>
                        Join Stream
                    </Button>
                </JoinStreamWrapper>
            }
            {streamToken !== '' && isAdmin === true && (['model', 'live_stream_manager'].includes(role)) && isModelCanGoLive === true &&
                <StreamWrapper isLoading={isLoading} setLiveStreamStatus={props.setLiveStreamStatus} streamToken={streamToken} onGoLive={goLive} onStopLive={stopLive} tabToken={tabToken} />
            }
            {room !== '' && pubId !== '' && pubPvtId !== '' && streamToken !== '' && isAdmin === false && isLive === true && streamJoined === true && userJoinLiveStream === true &&
                <StreamWrapper isLoading={isLoading} setLiveStreamStatus={props.setLiveStreamStatus} streamToken={streamToken} room={room} pubId={pubId} pubPvtId={pubPvtId} stopShowStream={stopShowStream} />
            }
        </>
    )
}

OuterStreamWrapper.propTypes = {
    auth: PropTypes.object.isRequired,
    liveStream: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    showBootstrapNavbar: PropTypes.func.isRequired,
    setStreamLoader: PropTypes.func.isRequired,
    setStartStream: PropTypes.func.isRequired,
    setLiveStreamStatus: PropTypes.func.isRequired,
    setIsLive: PropTypes.func.isRequired,
    setStreamJoined: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    liveStream: state.liveStream
})

export default connect(
    mapStateToProps,
    {
        setStartStream,
        setStreamLoader,
        showBootstrapNavbar,
        setLiveStreamStatus,
        setIsLive,
        setStreamJoined,
        sendTextMessage
    }
)(withRouter(OuterStreamWrapper))
